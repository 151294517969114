<template>
  <div class="container payment">
    <step :route="$route" class="payment-step">
      <div>
        <a href="javascript:;" class="bg-red" @click="pay">
          <i class="ion-social-usd"></i>
        </a>
        <p class="tab-text text-red">
          {{$t('common.button.pay')}}
        </p>
      </div>
    </step>
    <div class="row wrapper" v-if="!isMobile">
      <div class="col-xs-12 col-sm-10 col-sm-offset-1 payment-view">
        <router-view></router-view>
      </div>
    </div>
    <div class="wrapper" v-else>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'payment',
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
    pay () {
      this.$children[1].initCheckout()
    }
  }
}
</script>

<style type="text/scss" lang="scss">
  .payment {
    label {
      color: #9e9e9e;
      font-size: 14px;
    }
    label.active {
      color: #555;
    }
  }
</style>
